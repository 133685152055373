<template>
  <!-- <div class="tab_container">
    <div class="hint">
      <el-icon style="color: #3c6ff2; padding-right: 10px; font-size: 18px">
        <bell-filled />
      </el-icon>
      重点关注
    </div>
    <div class="hint_box">
      <div class="each">
        <el-button size="small">收费</el-button>
        <div class="each_text">
          <span style="font-weight: 800; padding-right: 5px">·</span
          >该客户已经欠费￥ 600.00
          <el-icon size="12">
            <arrow-right />
          </el-icon>
        </div>
      </div>
      <div class="each">
        <el-button size="small">收费</el-button>
        <div class="each_text">
          <span style="font-weight: 800; padding-right: 5px">·</span
          >该客户合同已到期，请联系续签
          <el-icon size="12">
            <arrow-right />
          </el-icon>
        </div>
      </div>
      <div class="each">
        <el-button size="small">工商</el-button>
        <div class="each_text">
          <span style="font-weight: 800; padding-right: 5px">·</span>"公司注册
          (网上申请) 210723002000000
          <el-icon size="12">
            <arrow-right />
          </el-icon>
        </div>
      </div>
    </div>
  </div> -->
  <!-- <div class="process">
    <div class="process_top">
      <div class="shu">客户标签</div>
      <div>
        <el-button size="small" class="outline">添加标签</el-button>
      </div>
    </div>
    <div style="padding-left: 10px">
      <el-button size="small" class="outline">客户还未交接过来</el-button>
    </div>
  </div> -->
  <!-- <div class="process">
    <div class="process_top">
      <div class="shu">税务进度</div>
      <div>
        <el-date-picker
          v-model="value"
          type="month"
          placeholder="选择账期"
          size="small"
          value-format="YYYYMM"
          style="width: 150px"
        ></el-date-picker>
      </div>
    </div>
    <div class="process_bottom">
      <el-button size="small" icon="CircleCheckFilled" style="color: #0fa157"
        >抄</el-button
      >-<el-button size="small" icon="CircleCheckFilled" style="color: #0fa157"
        >报</el-button
      >-<el-button size="small" icon="CircleCheckFilled" style="color: #0fa157"
        >缴</el-button
      >-<el-button size="small" icon="CircleCheckFilled" style="color: #0fa157"
        >清</el-button
      >-<el-button size="small" icon="CircleCheckFilled">个税</el-button
      >-<el-button size="small" icon="CircleCheckFilled">申报</el-button>
    </div>
  </div> -->
  <div v-loading="loading" class="main">
    <div class="top_select">
      <div>
        <el-select
          v-model="period"
          placeholder="请选择"
          size="small"
          @change="getList"
          style="width: 150px"
        >
          <el-option
            v-for="item in periods"
            :key="item.period"
            :label="item.period"
            :value="item.period"
          >
          </el-option>
        </el-select>
        <el-button type="text" icon="Refresh" @click="getList" size="small"
          >刷新</el-button
        >
      </div>
      <div>
        <el-dropdown
          size="small"
          style="margin-left: 10px; margin-right: 10px"
          :hide-on-click="false"
        >
          <el-button size="small" type="primary" plain>
            查看<el-icon class="el-icon--right"><arrow-down /></el-icon>
          </el-button>
          <template #dropdown>
            <el-dropdown-menu style="text-align: center">
              <el-dropdown-item command="a">
                <span @click="getTax">税款提醒</span>
              </el-dropdown-item>
              <el-dropdown-item command="b">
                <span @click="getGsInfo">个税提醒</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </div>
    <div class="process">
      <div class="process_top">
        <div class="shu">增值税</div>
        <div>
          <div>
            <el-icon color="#3c6ff2" @click="sendVat" style="cursor: pointer">
              <promotion />
            </el-icon>
          </div>
        </div>
      </div>
      <div class="amount">
        本月已开：{{ vatInfo.byykCount }}份 &nbsp;&nbsp;|&nbsp;&nbsp;
        {{ vatInfo.byykSum }}元
      </div>
      <div class="amount">
        进项未认证：{{ vatInfo.jxwrzCount }}份 &nbsp;&nbsp;|&nbsp;&nbsp;
        {{ vatInfo.jxwrzSum }}元
      </div>
      <div class="amount">
        进项已认证：{{ vatInfo.jxyrzCount }}份 &nbsp;&nbsp;|&nbsp;&nbsp;
        {{ vatInfo.jxyrzSum }}元
      </div>
      <div class="amount">上期留抵：{{ vatInfo.sqld }}元</div>
      <div class="amount">
        预计增值税：{{ vatInfo.yjzzs }}元（税负率:月
        {{ vatInfo.sf ? vatInfo.sf : 0 }} % | 年
        {{ vatInfo.sfYear ? vatInfo.sfYear : 0 }} %）
      </div>
      <div class="amount" style="color: #999">
        提示：该处数据供参考，请确保[发票]已提取最新
      </div>
    </div>
    <!-- <div class="process">
      <div class="process_top">
        <div class="shu">综合所得个人所得税</div>
        <div>
          <div>
            <el-icon color="#3c6ff2" @click="sendGs" style="cursor: pointer">
              <promotion />
            </el-icon>
          </div>
        </div>
      </div>
      <div class="process_top" style="padding: 10px 0 0 10px">
        <div class="each_sds">总人数：{{ gsInfo.total }}</div>
        <div class="each_sds">总工资金额：{{ gsInfo.salarySum }}</div>
      </div>
      <div class="process_top" style="padding: 10px 0 0 10px">
        <div class="each_sds">税额：{{ gsInfo.gsSum }}</div>
      </div>
    </div> -->
    <div class="process">
      <div class="process_top">
        <div class="shu">所得税</div>
        <div>
          <div>
            <el-icon
              color="#3c6ff2"
              @click="sendQuarter"
              style="cursor: pointer"
            >
              <promotion />
            </el-icon>
          </div>
        </div>
      </div>
      <div class="process_top" style="padding: 10px 0 0 10px">
        <div class="each_sds">本年收入：{{ quarterInfo.yysr }}</div>
        <div class="each_sds">本年成本：{{ quarterInfo.yycb }}</div>
      </div>
      <div class="process_top" style="padding: 10px 0 0 10px">
        <div class="each_sds">利润总额：{{ quarterInfo.lrze }}</div>
        <div class="each_sds">实际利润额：{{ quarterInfo.sjlre }}</div>
      </div>
      <!-- <div class="process_top" style="padding: 10px 0 0 10px">
        <div class="each_sds">应纳所得税额：{{ quarterInfo.ynssde }}</div>
      </div> -->
    </div>
    <!--  <div class="process">
      <div class="process_top">
        <div class="shu">资产负债表</div>
        <div>
          <div>
            <el-icon color="#3c6ff2" @click="sendZcfz" style="cursor: pointer">
              <promotion />
            </el-icon>
          </div>
        </div>
      </div>
      <div class="amount">资产合计(期末余额)：{{ zcfzInfo.zchjQm }}</div>
      <div class="amount">负债合计(期末余额)：{{ zcfzInfo.fzhjQm }}</div>
    </div> -->
    <!--  <div class="process">
      <div class="process_top">
        <div class="shu">利润表</div>
        <div>
          <div>
            <el-icon color="#3c6ff2" @click="sendLrb" style="cursor: pointer">
              <promotion />
            </el-icon>
          </div>
        </div>
      </div>
      <div class="process_top" style="padding: 10px 0 0 10px">
        <div class="each_sds">营业收入：{{ lrbInfo.yysr }}</div>
        <div class="each_sds">营业成本：{{ lrbInfo.yycb }}</div>
      </div>
      <div class="process_top" style="padding: 10px 0 0 10px">
        <div class="each_sds">利润总额：{{ lrbInfo.lrze }}</div>
        <div class="each_sds">净利润：{{ lrbInfo.jlr }}</div>
      </div>
    </div> -->
  </div>
  <el-dialog
    :close-on-click-modal="false"
    v-model="dialogGsVisible"
    title="个税提醒-预览"
    width="95%"
    destroy-on-close
    center
  >
    <div class="info">
      <div>【税务温馨提示】</div>
      <div>尊敬的老板，您的企业 “ {{ comName }} ” 的个税申报情况如下：</div>
      <div>
        所属月份：{{ period }}<br />
        申报状态：{{ gsInfo.sbStatus }}<br />扣款状态：{{ gsInfo.kkStatus }}
      </div>
      <div>总人数：{{ gsInfo.total }}人</div>
      <div>总工资金额：{{ gsInfo.salarySum }}元</div>
      <div>应纳税额：{{ gsInfo.gsSum }}元</div>
      <div>——————</div>
      <div>员工清单：</div>
      <div class="person">
        <div>姓名</div>
        <div style="text-align: center">收入</div>
        <div style="text-align: right">个税金额</div>
      </div>
      <div class="person" v-for="item in gsInfo.list">
        <div>{{ item.name }}</div>
        <div style="text-align: center">{{ item.salary }}</div>
        <div style="text-align: right">{{ item.deductIncomeTax }}</div>
      </div>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button size="small" type="primary" @click="sendGs"
          >确认发送</el-button
        >
      </span>
    </template>
  </el-dialog>
  <el-dialog
    :close-on-click-modal="false"
    v-model="dialogTaxVisible"
    title="税款提醒-预览"
    width="95%"
    destroy-on-close
    center
  >
    <div class="info">
      <div>【税务温馨提示】</div>
      <div>尊敬的老板，您的企业 “ {{ comName }} ” 的税款情况如下：</div>
      <div>所属月份：{{ period }}</div>
      <div>——————</div>
      <div class="person">
        <div style="width: 60%">税种</div>
        <div style="width: 38%">税款</div>
      </div>
      <div class="person" v-for="item in taxInfo">
        <div style="width: 60%">{{ $tableNameFilter(item.type) }}</div>
        <div style="width: 38%">
          {{ item.tax ? item.tax : "0" }}
          <span v-if="item.fjx"> + {{ item.fjx }}</span>
        </div>
      </div>

      <div class="person">
        <div style="width: 60%">增值税及附加税费</div>
        <div style="width: 38%">
          {{ vatFjs.vatTax ? vatFjs.vatTax : "0" }}
          <span v-if="vatFjs.vatTaxFjs"> + {{ vatFjs.vatTaxFjs }}</span>
        </div>
      </div>
      <div class="person">
        <div style="width: 60%">城市维护建设费</div>
        <div style="width: 38%">{{ vatFjs.cjs }}</div>
      </div>
      <div class="person">
        <div style="width: 60%">教育费附加</div>
        <div style="width: 38%">{{ vatFjs.jyf }}</div>
      </div>
      <div class="person">
        <div style="width: 60%">地方教育费附加</div>
        <div style="width: 38%">{{ vatFjs.dfjyf }}</div>
      </div>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button size="small" type="primary" @click="sendTax"
          >确认发送</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { onMounted, ref, getCurrentInstance } from "vue";
import { qywxTaxVatAmount, gsTaxInfo, taxParticulars } from "@/api/home";
import { ElMessage } from "element-plus";
const { proxy } = getCurrentInstance();
const comId = ref(null);
const taxInfo = ref([]);
const vatInfo = ref({});
const quarterInfo = ref({});
const zcfzInfo = ref({});
const lrbInfo = ref({});
const gsInfo = ref({});
const periods = ref([]);
const period = ref();
const comName = ref("");
const loading = ref(false);
const dialogGsVisible = ref(false);
const dialogTaxVisible = ref(false);
const vatFjs = ref({});
const taxAmount = ref("");
const init = (id, name) => {
  comId.value = id;
  comName.value = name;
  periods.value = proxy.$store.getters["user/periods"];
  period.value = periods.value[0].period;
  getList();
};
const getList = () => {
  loading.value = true;
  qywxTaxVatAmount({
    comId: comId.value,
    period: period.value,
  }).then((res) => {
    loading.value = false;
    if (res.data.msg == "success") {
      vatInfo.value = res.data.data.list;
      quarterInfo.value = res.data.data.list1;
      zcfzInfo.value = res.data.data.kjZcfz;
      lrbInfo.value = res.data.data.kjLrb;
    }
  });
};
//税款提醒
const getTax = () => {
  loading.value = true;
  taxParticulars({
    comId: comId.value,
    period: period.value,
  }).then((res) => {
    loading.value = false;
    if (res.data.msg == "success") {
      dialogTaxVisible.value = true;
      taxInfo.value = res.data.data.info;
      vatFjs.value = res.data.data.vatFjs;
      taxAmount.value = res.data.data.taxAmount;
    }
  });
};
//个税提醒
const getGsInfo = () => {
  loading.value = true;
  gsTaxInfo({
    comId: comId.value,
    period: period.value,
  }).then((res) => {
    loading.value = false;
    if (res.data.msg == "success") {
      gsInfo.value = res.data.data.list;
      gsInfo.value.kkStatus = res.data.data.kkStatus;
      gsInfo.value.sbStatus = res.data.data.sbStatus;
      dialogGsVisible.value = true;
    }
  });
};
//发送增值税
const sendVat = () => {
  loading.value = true;
  let sf = vatInfo.value.sf ? vatInfo.value.sf : 0;
  let sfYear = vatInfo.value.sfYear ? vatInfo.value.sfYear : 0;
  let jxse = (vatInfo.value.jxwrzSum * 1 + vatInfo.value.jxyrzSum * 1).toFixed(
    2
  );
  wx.invoke(
    "sendChatMessage",
    {
      msgtype: "text", //消息类型，必填
      enterChat: true, //为true时表示发送完成之后顺便进入会话，仅移动端3.1.10及以上版本支持该字段
      text: {
        content:
          "【税务温馨提示】\n尊敬的老板，您的企业“" +
          comName.value +
          "”的" +
          period.value +
          "期增值税情况如下：\n——————\n开票收入：" +
          vatInfo.value.kpsrSum +
          "元，\n销项税额：" +
          vatInfo.value.byykSum +
          "元，\n本期进项税额：" +
          jxse +
          "元，\n上期留抵：" +
          vatInfo.value.sqld +
          "元，\n截止当下数据需要缴纳增值税：" +
          vatInfo.value.yjzzs +
          "元，\n（税负率:月 " +
          sf +
          " %|年 " +
          sfYear +
          " %)\n请注意核实，如有任何异议或在月底前有新增开票，请及时与我司沟通。",
      },
    },
    function (res) {
      loading.value = false;
      if (res.err_msg == "sendChatMessage:ok") {
        ElMessage.success("发送成功");
      } else {
      }
    }
  );
};
//发送企业所得税
const sendQuarter = () => {
  loading.value = true;
  wx.invoke(
    "sendChatMessage",
    {
      msgtype: "text", //消息类型，必填
      enterChat: true, //为true时表示发送完成之后顺便进入会话，仅移动端3.1.10及以上版本支持该字段
      text: {
        content:
          "【税务温馨提示】\n尊敬的老板，您的企业“" +
          comName.value +
          "”，截止当前，现汇报本年财税情况，请知悉：\n——————\n截止目前本年收入：" +
          quarterInfo.value.yysr +
          "\n截止目前本年成本：" +
          quarterInfo.value.yycb +
          "\n截止目前本年利润：" +
          quarterInfo.value.lrze +
          "\n实际利润额：" +
          quarterInfo.value.sjlre,
      },
    },
    function (res) {
      loading.value = false;
      if (res.err_msg == "sendChatMessage:ok") {
        ElMessage.success("发送成功");
      } else {
      }
    }
  );
};
//发送资产负债
const sendZcfz = () => {
  loading.value = true;
  wx.invoke(
    "sendChatMessage",
    {
      msgtype: "text", //消息类型，必填
      enterChat: true, //为true时表示发送完成之后顺便进入会话，仅移动端3.1.10及以上版本支持该字段
      text: {
        content:
          "【税务温馨提示】\n尊敬的老板，您的企业“" +
          comName.value +
          "”的" +
          period.value +
          "期资产负债表情况如下：\n——————\n资产合计(期末余额)：" +
          zcfzInfo.value.zchjQm +
          "\n负债合计(期末余额)：" +
          zcfzInfo.value.fzhjQm,
      },
    },
    function (res) {
      loading.value = false;
      if (res.err_msg == "sendChatMessage:ok") {
        ElMessage.success("发送成功");
      } else {
      }
    }
  );
};
//发送利润表
const sendLrb = () => {
  loading.value = true;
  wx.invoke(
    "sendChatMessage",
    {
      msgtype: "text", //消息类型，必填
      enterChat: true, //为true时表示发送完成之后顺便进入会话，仅移动端3.1.10及以上版本支持该字段
      text: {
        content:
          "【税务温馨提示】\n尊敬的老板，您的企业“" +
          comName.value +
          "”的" +
          period.value +
          "期利润表情况如下：\n——————\n营业收入：" +
          lrbInfo.value.yysr +
          "   营业成本：" +
          lrbInfo.value.yycb +
          "\n利润总额：" +
          lrbInfo.value.lrze +
          "   净利润：" +
          lrbInfo.value.jlr,
      },
    },
    function (res) {
      loading.value = false;
      if (res.err_msg == "sendChatMessage:ok") {
        ElMessage.success("发送成功");
      } else {
      }
    }
  );
};
//发送个税
const sendGs = () => {
  loading.value = true;
  let str = "";
  for (let i = 0; i < gsInfo.value.list.length; i++) {
    str +=
      gsInfo.value.list[i].name +
      "\t" +
      gsInfo.value.list[i].salary +
      "\t" +
      gsInfo.value.list[i].deductIncomeTax +
      "\n";
  }
  wx.invoke(
    "sendChatMessage",
    {
      msgtype: "text", //消息类型，必填
      enterChat: true, //为true时表示发送完成之后顺便进入会话，仅移动端3.1.10及以上版本支持该字段
      text: {
        content:
          "【税务温馨提示】\n尊敬的老板，您的企业“" +
          comName.value +
          "”的" +
          period.value +
          "期个税申报情况如下：\n——————\n申报状态：" +
          gsInfo.value.sbStatus +
          "；扣款状态：" +
          gsInfo.value.kkStatus +
          "\n——————\n总人数：" +
          gsInfo.value.total +
          "人\n总工资金额：" +
          gsInfo.value.salarySum +
          "元\n个税金额：" +
          gsInfo.value.gsSum +
          "元\n——————\n员工清单：\n姓名\t收入\t个税金额\n" +
          str +
          "\n请您核对确认，如有问题请与我联系!",
      },
    },
    function (res) {
      loading.value = false;
      if (res.err_msg == "sendChatMessage:ok") {
        dialogGsVisible.value = false;
        ElMessage.success("发送成功");
      } else {
      }
    }
  );
};
//发送税款
const sendTax = () => {
  loading.value = true;
  let year = period.value.slice(0, 4);
  let month = period.value.slice(4, 6);
  let str = "";
  for (let i = 0; i < taxInfo.value.length; i++) {
    let count = taxInfo.value[i].tax ? taxInfo.value[i].tax : "0";
    let amount = "";
    if (taxInfo.value[i].fjx) {
      amount = count + "+" + taxInfo.value[i].fjx;
    } else {
      amount = count;
    }
    str +=
      proxy.$tableNameFilter(taxInfo.value[i].type) + "：" + amount + "元\n";
  }
  let vatStr = "";
  vatStr =
    "增值税及附加税费：" +
    vatFjs.value.vatTax +
    "元；附加税：" +
    vatFjs.value.vatTaxFjs +
    "元，其中：\n\t城市维护建设费：" +
    vatFjs.value.cjs +
    "元\n\t教育费附加：" +
    vatFjs.value.jyf +
    "元\n\t地方教育费附加：" +
    vatFjs.value.dfjyf +
    "元\n";
  wx.invoke(
    "sendChatMessage",
    {
      msgtype: "text", //消息类型，必填
      enterChat: true, //为true时表示发送完成之后顺便进入会话，仅移动端3.1.10及以上版本支持该字段
      text: {
        content:
          "【税务温馨提示】\n尊敬的老板，您的企业「" +
          comName.value +
          "」" +
          year +
          "年" +
          month +
          "月税款如下：\n" +
          vatStr +
          str +
          "\n-------总计：" +
          taxAmount.value +
          "元" +
          "\n请您核对确认，如有问题请与我联系!",
      },
    },
    function (res) {
      loading.value = false;
      if (res.err_msg == "sendChatMessage:ok") {
        dialogTaxVisible.value = false;
        ElMessage.success("发送成功");
      } else {
      }
    }
  );
};
defineExpose({
  init,
});
</script>

<style scoped lang="scss">
.main {
  padding-bottom: 15px;
  font-size: 14px;
}
.tab_container {
  width: 98%;
  margin: 0 auto;
  border: 1px solid #efefef;
  border-radius: 8px;
  padding-bottom: 15px;
}
.hint {
  display: flex;
  align-items: center;
  font-weight: 600;
  padding: 10px 0 10px 10px;
}
.hint_box {
  padding-left: 10px;
}
.each {
  display: flex;
  align-items: center;
  margin-top: 5px;
}
.each_text {
  width: 70%;
  color: #3c6ff2;
  padding-left: 10px;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.each .el-button--small {
  padding: 2px 7px;
}

.process {
  padding: 15px 0;
  border-bottom: 1px solid #efefef;
  background-color: #fff;
  margin: 15px 10px 0 10px;
  border-radius: 8px;
}
.process_top {
  width: 96%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 5px;
  font-size: 15px;
}
.shu {
  padding-left: 10px;
  border-left: 2px solid #3c6ff2;
}
.process_bottom {
  width: 96%;
  display: flex;
  align-items: center;
  padding-left: 10px;
  color: #bbb;
}
.process_bottom .el-button + .el-button {
  margin-left: 0px;
}
.amount {
  margin-top: 10px;
  font-size: 14px;
  padding-left: 10px;
}
.each_sds {
  width: 50%;
  font-size: 14px;
}
.top_select {
  margin: 15px 0 0 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.info {
  div {
    font-size: 14px;
    line-height: 24px;
  }
}
.person {
  display: flex;
  align-items: center;
  justify-content: space-between;
  div {
    width: 33%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}
</style>
