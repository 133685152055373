import { login, getQywxPeriods } from "@/api/home";
import { getToken, setToken, removeToken } from "@/utils/auth";
import Message from "element-plus";
export const user = {
  namespaced: true,
  state: {
    token: "",
    periods: [], //账期列表
  },
  mutations: {
    setPeriods(state, periods) {
      state.periods = periods;
    },
  },
  actions: {
    Login({ commit }, userInfo) {
      const chatId = userInfo;
      login({ chatId: chatId }).then((res) => {
        if (res.data.msg == "success") {
          setToken(res.data.token);
        } else {
          Message({
            message: res.data.msg,
            type: res.data.msg,
          });
        }
      });
    },
    InitPeriod({ commit }, e) {
      return new Promise((resolve, reject) => {
        let periods = [];
        getQywxPeriods({ comId: e })
          .then((res) => {
            periods = res.data.data;
            commit("setPeriods", periods);
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
  getters: {
    periods(state) {
      return state.periods;
    },
  },
};
