<template>
  <div class="main" v-loading="loading">
    <div class="input_class">
      <!-- <div>
        <el-input
          v-model="likeName"
          placeholder="搜索你要的文件"
          size="small"
          suffix-icon="el-icon-search"
          style="width: 55%"
        >
        </el-input>
        <el-button type="primary" icon="Search" size="small">搜索</el-button>
      </div> -->
      <div>
        <el-button
          size="small"
          type="success"
          icon="Plus"
          @click="addFile"
          plain
          >新建文件夹</el-button
        >
      </div>
    </div>
    <p class="title">所有文件</p>
    <!-- <div class="btn_div">
      <div class="btn">
        <el-button
          type="primary"
          icon="Upload"
          size="small"
          :style="{ width: '80%' }"
          >点击上传</el-button
        >
      </div>
      <div class="btn">
        <el-button
          size="small"
          icon="Plus"
          :style="{ width: '80%' }"
          @click="addFile"
          >新建文件夹</el-button
        >
      </div>
    </div> -->
    <div style="margin-top: 20px" v-if="fileList.length != 0">
      <div class="list_div" v-for="(item, index) in fileList">
        <div class="list_list">
          <div class="list_left" @click="open(item)">
            <div style="display: flex; align-items: center; width: 100%">
              <i class="iconfont icon-weibiaoti-_huabanfuben"></i>

              <div
                style="
                  display: flex;
                  flex-direction: column;
                  margin-left: 15px;
                  width: 80%;
                "
              >
                <p class="wj_title">{{ item.name }}</p>
                <div
                  style="
                    display: flex;
                    align-items: center;
                    color: #afaeae;
                    font-size: 12px;
                    margin-top: 5px;
                  "
                >
                  <p v-if="item.item != null">
                    {{ item.item.length ? item.item.length : 0 }}项
                  </p>
                  <p v-else>0项</p>
                  <p style="margin-left: 25px">{{ item.createTime }}</p>
                </div>
              </div>
            </div>
          </div>
          <el-popover
            placement="left"
            trigger="click"
            width="70"
            ref="popover"
            popper-class="classpopper"
          >
            <template #reference>
              <i class="iconfont icon-gengduo-shuxiang"></i>
            </template>

            <p class="open_text" @click="uploadFile(item)">上传</p>
            <p
              class="open_text"
              style="margin-top: 5px"
              @click="delFile(item, 2)"
            >
              删除
            </p>
          </el-popover>
        </div>
        <div
          v-if="item.open"
          style="width: 96%; margin: 0 auto; margin-top: 10px"
        >
          <div v-for="(item1, index) in item.item" class="list_div">
            <div class="list_list">
              <div class="list_left" @click="check(item1)">
                <div style="display: flex; align-items: center; width: 100%">
                  <i
                    class="iconfont icon-wenjianleixing-biaozhuntu-gongzuobiao"
                    v-if="item1.fileType == 'xlsx' || item1.fileType == 'xls'"
                  ></i>
                  <i
                    class="iconfont icon-wenjianleixing-biaozhuntu-jishiben"
                    v-else-if="item1.fileType == 'txt'"
                  ></i>
                  <i
                    class="iconfont icon-wenjianleixing-biaozhuntu-huandengpian"
                    v-else-if="item1.fileType == 'ppt'"
                  ></i>
                  <i
                    class="iconfont icon-wenjianleixing-biaozhuntu-yasuowenjian"
                    v-else-if="item1.fileType == 'zip'"
                  ></i>
                  <i
                    class="iconfont icon-wenjianleixing-biaozhuntu-PDFwendang"
                    v-else-if="item1.fileType == 'pdf'"
                  ></i>
                  <i
                    class="iconfont icon-wenjianleixing-biaozhuntu-Wordwendang"
                    v-else-if="
                      item1.fileType == 'docx' || item1.fileType == 'doc'
                    "
                  ></i>
                  <i
                    class="iconfont icon-wenjianleixing-biaozhuntu-weizhiwenjian"
                    v-else
                  ></i>
                  <div
                    style="
                      display: flex;
                      flex-direction: column;
                      margin-left: 15px;
                      width: 80%;
                    "
                  >
                    <p class="wj_title" style="font-size: 12px">
                      {{ item1.name }}
                    </p>
                    <div
                      style="color: #afaeae; font-size: 12px; margin-top: 5px"
                    >
                      <p>{{ item1.createTime }}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <el-icon
                  color="red"
                  style="font-size: 14px"
                  @click="delFile(item1, 1)"
                >
                  <delete />
                </el-icon>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="margin-top: 20px" v-else>
      <el-empty description="暂无文件"></el-empty>
    </div>
  </div>
  <!-- 新建文件夹start -->
  <el-dialog
    title="新建文件夹"
    v-model="dialogVisible"
    width="80%"
    :before-close="handleClose"
  >
    <el-form>
      <el-form-item label="文件夹名称">
        <el-input v-model="fileName" placeholder="请输入"></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="addSure" size="small"
          >确 定</el-button
        >
      </span>
    </template>
  </el-dialog>
  <!-- 新建文件夹end -->
  <!-- 上传文件start -->
  <el-dialog
    title="文件上传"
    v-model="dialogUploadVisible"
    width="80%"
    :before-close="handleClose"
  >
    <el-input style="width: 60%; margin-right: 10px" v-model="path"></el-input>
    <input
      size="small"
      ref="inputRef"
      class="excel-upload-input"
      style="display: none"
      type="file"
      @change="handleClick"
    />
    <el-button type="primary" @click="handleUpload()" size="small"
      >请选择文件</el-button
    >

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogUploadVisible = false" size="small"
          >取 消</el-button
        >
        <el-button type="primary" @click="uploadExcel" size="small"
          >确 定</el-button
        >
      </span>
    </template>
  </el-dialog>
  <!-- 上传文件end -->
</template>
<script setup>
import { ref, getCurrentInstance } from "vue";
import { getFFList, delFF, createFolder, upload } from "@/api/file";
import { ElMessage } from "element-plus";
const { proxy } = getCurrentInstance();
const inputRef = ref(null);
const comId = ref(null);
const fileName = ref("");
const multipartFile = ref({
  id: 1,
});
const fileList = ref([]);
const dialogVisible = ref(false);
const dialogUploadVisible = ref(false);
const rawFile = ref("");
const path = ref("");
const url = ref("");
const folderId = ref(null);
const loading = ref(false);
const taxNo = ref("");
const init = (id, e) => {
  comId.value = id;
  taxNo.value = e;
  reqet();
};
const reqet = () => {
  loading.value = true;
  getFFList({
    taxNo: taxNo.value,
  }).then((res) => {
    loading.value = false
    if (res.data.msg == "success") {
      fileList.value = res.data.data ? res.data.data : [];
    }
  });
};
//新建文件夹
const addFile = () => {
  dialogVisible.value = true;
  fileName.value = "";
};
const addSure = () => {
  createFolder({
    taxNo: taxNo.value,
    name: fileName.value,
  }).then((res) => {
    if (res.data.msg == "success") {
      ElMessage.success("新增成功");
      reqet();
      dialogVisible.value = false;
    }
  });
};
///上传文件
const uploadFile = (item) => {
  dialogUploadVisible.value = true;
  folderId.value = item.id;
};
const handleUpload = () => {
  inputRef.value.click();
};
const handleClick = (e) => {
  let files = e.target.files;
  rawFile.value = files[0]; // only use files[0]
  path.value = e.target.value; //路径
};
const uploadExcel = () => {
  if (rawFile.value == "") {
    Message.warning("请选择文件");
    return;
  }
  const param = new FormData();
  param.append("file", rawFile.value, rawFile.value.name);
  param.append("taxNo", taxNo.value);
  param.append("folderId", folderId.value);
  upload(param).then((res) => {
    if (res.data.msg == "success") {
      ElMessage.success("上传成功");
      dialogUploadVisible.value = false;
      reqet();
    }
  });
};
const open = (item) => {
  if (item.item != null && item.item.length > 0) {
    item.open = !item.open;
  }
};
//查看文件
const check = (item) => {
  window.open(item.filePath);
};
//删除文件
const delFile = (item, type) => {
  proxy
    .$confirm("确认删除该文件？", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    })
    .then(() => {
      delFF({
        id: item.id,
        fileType: type,
      }).then((res) => {
        if (res.data.msg == "success") {
          ElMessage.success("成功删除");
          reqet();
        }
      });
    })
    .catch((err) => {});
};
defineExpose({
  init,
});
</script>
<style scoped lang="scss">
.main {
  // height: 75vh;
  background-color: #fff;
  padding: 10px 0;
}
.input_class {
  width: 90%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.fdj {
  font-size: 20px;
  margin-right: 10px;
}

.title {
  color: #d6d5d5;
  margin-left: 20px;
  font-size: 12px;
  margin-top: 10px;
}

.btn_div {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
  .btn {
    width: 45%;
    text-align: center;
  }
}

.sc {
  font-size: 16px;
}

.click_sc {
  color: #fff;
  font-size: 13px;
  margin-left: 5px;
}

.list_div {
  // margin-top: 20px;
}

.list_list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 7px;
}

.wj_title {
  font-size: 13px;
  width: 70%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.upload-demo {
  width: 80%;
  margin-top: 20px;
}

.open_text {
  font-size: 10px;
  text-align: center;
  cursor: pointer;
}
.file_title {
  display: flex;
  align-items: center;
}
.list_left {
  width: 90%;
}
.iconfont {
  font-size: 20px;
  margin-left: 10px;
}
</style>
