import { createApp } from "vue";
import ElementPlus from "element-plus";
import * as ElIcons from "@element-plus/icons-vue";
import locale from "element-plus/lib/locale/lang/zh-cn";
import "element-plus/dist/index.css";
import './assets/icon/iconfont.css'
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./style/element.scss";
import { parseTime, getHeight, getLastDay } from "@/utils/index";
import {
  fplx,
  inventoryTypeFilter,
  inventoryCategoryFilter,
  VoucherSourceFilter,
  tableNameFilter,
  taxPayerTypeFilter,
  setUpStatusSb,
  setUpStatusGsSb,
  setUpStatusQS,
  setUpStatusQSIcon,
  setUpStatusGsKk,
  setUpStatusGsIconSb,
  setUpStatusGsWordSb,
  setUpStatusKk,
  setUpStatusJc,
  setUpStatusIconJc,
  setUpStatusCj,
  setUpStatusQY,
  setUpStatusImport,
  setUpStatusMatch,
  batchSendSb,
  batchSendIconSb,
  batchSendWordSb,
  batchSendKk,
  batchSendWordKk,
  batchSendJc,
  batchSendCj,
  batchSendIconCj,
  setUpStatusGsZf,
  setUpStatusGsIconZf,
  setUpStatusGsWordZf,
  setUpStatusJzCj,
  setUpStatusJzCjIcon,
  cityFilter,
  yearAcessStatus,
  yearAcessIconStatus,
  yearAcessWordStatus,
  batchSendYearSb,
} from "@/utils/filter";
const app = createApp(App);
app.mixin({
  methods: {
    $parseTime: parseTime,
    $getHeight: getHeight,
    $getLastDay: getLastDay,
    $batchSendSb: batchSendSb,
    $batchSendIconSb: batchSendIconSb,
    $batchSendWordSb: batchSendWordSb,
    $tableNameFilter: tableNameFilter,
    $batchSendKk: batchSendKk,
    $batchSendWordKk: batchSendWordKk,
    $batchSendJc: batchSendJc,
    $batchSendCj: batchSendCj,
    $batchSendIconCj: batchSendIconCj,
    $setUpStatusGsZf: setUpStatusGsZf,
    $setUpStatusGsIconZf: setUpStatusGsIconZf,
    $setUpStatusGsSb: setUpStatusGsSb,
    $setUpStatusGsWordZf: setUpStatusGsWordZf,

    $setUpStatusJzCj: setUpStatusJzCj,
    $setUpStatusJzCjIcon: setUpStatusJzCjIcon,
    $setUpStatusQS: setUpStatusQS,
    $setUpStatusQSIcon: setUpStatusQSIcon,
    $setUpStatusGsIconSb: setUpStatusGsIconSb,
    $yearAcessStatus: yearAcessStatus,
    $yearAcessIconStatus: yearAcessIconStatus,
    $yearAcessWordStatus: yearAcessWordStatus,
    $batchSendYearSb: batchSendYearSb,
  },
});
for (const iconName in ElIcons) {
  app.component(iconName, ElIcons[iconName]);
}
app.use(ElementPlus, { locale }).use(store).use(router).mount("#app");
