<template>
  <div class="main" v-loading="loading">
    <div class="top">
      <el-radio-group v-model="type" size="medium" @change="getList">
        <el-radio-button :label="1">财务报表</el-radio-button>
        <el-radio-button :label="2">税务报表</el-radio-button>
      </el-radio-group>
    </div>
    <div class="top_content">
      <div class="date">
        <span style="font-size: 14px">会计所属期</span>
        <el-select
          v-model="period"
          placeholder="请选择"
          size="small"
          @change="getList"
        >
          <el-option
            v-for="item in periods"
            :key="item.period"
            :label="item.period"
            :value="item.period"
          >
          </el-option>
        </el-select>
      </div>
    </div>
    <div v-if="type == 1">
      <div v-if="accountSystem == '民办非'">
        <div class="list_div" v-for="(item, index) in list2">
          <div class="div_box">
            <div style="display: flex; align-items: center">
              <i class="iconfont icon-wenjianleixing-biaozhuntu-PDFwendang"></i>
              <p class="bb_name">{{ item.name }}</p>
            </div>
            <div style="display: flex; align-items: center">
              <el-button
                type="success"
                size="small"
                plain
                @click="cwbbDownload(item)"
                :loading="item.downLoading"
                >下载</el-button
              >
              <el-button
                type="primary"
                size="small"
                plain
                @click="sendCwbb(item)"
                :loading="item.sendLoading"
                >发送</el-button
              >
            </div>
          </div>
          <div class="line"></div>
        </div>
      </div>
      <div v-else-if="accountSystem == '农业合作社'">
        <div class="list_div" v-for="(item, index) in list3">
          <div class="div_box">
            <div style="display: flex; align-items: center">
              <i class="iconfont icon-wenjianleixing-biaozhuntu-PDFwendang"></i>
              <p class="bb_name">{{ item.name }}</p>
            </div>
            <div style="display: flex; align-items: center">
              <el-button
                type="success"
                size="small"
                plain
                @click="cwbbDownload(item)"
                :loading="item.downLoading"
                >下载</el-button
              >
              <el-button
                type="primary"
                size="small"
                plain
                @click="sendCwbb(item)"
                :loading="item.sendLoading"
                >发送</el-button
              >
            </div>
          </div>
          <div class="line"></div>
        </div>
      </div>
      <div v-else>
        <div class="list_div" v-for="(item, index) in list1">
          <div class="div_box">
            <div style="display: flex; align-items: center">
              <i class="iconfont icon-wenjianleixing-biaozhuntu-PDFwendang"></i>
              <p class="bb_name">{{ item.name }}</p>
            </div>
            <div style="display: flex; align-items: center">
              <el-button
                type="success"
                size="small"
                plain
                @click="cwbbDownload(item)"
                :loading="item.downLoading"
                >下载</el-button
              >
              <el-button
                type="primary"
                size="small"
                plain
                @click="sendCwbb(item)"
                :loading="item.sendLoading"
                >发送</el-button
              >
            </div>
          </div>
          <div class="line"></div>
        </div>
      </div>
    </div>
    <div v-if="type == 2">
      <div class="list_div" v-for="(item, index) in taxList">
        <div class="div_box" v-if="item.tableName != 'gs_cwbb'">
          <div style="display: flex; align-items: center">
            <i class="iconfont icon-wenjianleixing-biaozhuntu-PDFwendang"></i>
            <p class="bb_name">{{ $tableNameFilter(item.tableName) }}</p>
          </div>
          <div style="display: flex; align-items: center">
            <el-button
              type="success"
              size="small"
              plain
              @click="taxDownload(item)"
              :loading="item.downLoading"
              >下载</el-button
            >
            <el-button
              type="primary"
              size="small"
              plain
              @click="sendSwbb(item)"
              :loading="item.sendLoading"
              >发送</el-button
            >
          </div>
        </div>
        <div class="line" v-if="item.tableName != 'gs_cwbb'"></div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, getCurrentInstance } from "vue";
import { qywxTaxList, qywxPrintReport } from "@/api/home";
import { ElMessage } from "element-plus";
const { proxy } = getCurrentInstance();
const comId = ref(null);
const type = ref(1);
const periods = ref([]);
const period = ref();
const taxList = ref([]);
const loading = ref(false);
const accountSystem = ref("小企业会计准则2013版");
const list1 = ref([
  //小企业会计准则2013版  一般企业会计准则  企业会计制度  一般企业会计准则(已执行新金融准则)
  {
    name: "资产负债表",
    tableName: "kj_zcfz",
    itemName: "",
  },
  {
    name: "利润表(季报)",
    tableName: "kj_lrb",
    itemName: "",
  },
  {
    name: "利润表(月报)",
    tableName: "kj_lrb",
    itemName: "month",
  },
  {
    name: "利润统计表",
    tableName: "kj_lrb",
    itemName: "year",
  },
  {
    name: "现金流量表(月报)",
    tableName: "kj_xjllb",
    itemName: "month",
  },
  {
    name: "现金流量表(季报)",
    tableName: "kj_xjllb",
    itemName: "",
  },
]);
const list2 = ref([
  //民办非
  {
    name: "资产负债表",
    tableName: "kj_zcfz",
    itemName: "",
  },
  {
    name: "业务活动表(月报)",
    tableName: "kj_lrb",
    itemName: "month",
  },
  {
    name: "业务活动表(季报)",
    tableName: "kj_lrb",
    itemName: "",
  },
]);
const list3 = ref([
  //农村合作社
  {
    name: "资产负债表",
    tableName: "kj_zcfz",
    itemName: "",
  },
  {
    name: "盈余及盈余分配表",
    tableName: "kj_lrb",
    itemName: "",
  },
  {
    name: "成员权益变动表",
    tableName: "kj_lrb",
    itemName: "cy",
  },
]);
const init = (id, type) => {
  comId.value = id;
  accountSystem.value = type;
  periods.value = proxy.$store.getters["user/periods"];
  period.value = periods.value[0].period;
  getList()
};
const getList = () => {
  if (type.value == 2) {
    loading.value = true;
    qywxTaxList({
      comId: comId.value,
      period: period.value,
    }).then((res) => {
      loading.value = false;
      if (res.data.msg == "success") {
        taxList.value = res.data.data.list;
      }
    });
  }
};
//下载财务报表
const cwbbDownload = (item) => {
  item.downLoading = true;
  let params = {
    comIds: [comId.value],
    period: period.value,
    bookNames: [item.tableName],
    itemName: item.itemName,
    merge: 1,
    from: "kjbb",
    mediaId: false,
  };
  qywxPrintReport(params).then((res) => {
    item.downLoading = false;
    if (res.data.msg == "success") {
      window.open(res.data.data.url);
    }
  });
};
//发送财务报表
const sendCwbb = (item) => {
  item.sendLoading = true;
  let params = {
    comIds: [comId.value],
    period: period.value,
    bookNames: [item.tableName],
    itemName: item.itemName,
    merge: 1,
    from: "kjbb",
    mediaId: true,
  };
  qywxPrintReport(params).then((res) => {
    item.sendLoading = false;
    if (res.data.msg == "success") {
      wx.invoke(
        "sendChatMessage",
        {
          msgtype: "file", //消息类型，必填
          file: {
            mediaid: res.data.data.mediaId, //文件的素材id
          },
        },
        function (res) {
          if (res.err_msg == "sendChatMessage:ok") {
            ElMessage.success("发送成功");
          } else {
          }
        }
      );
    }
  });
};
///下载税务报表
const taxDownload = (item) => {
  item.downLoading = true;
  let param = {
    comIds: [comId.value],
    period: period.value,
    bookNames: [item.tableName],
    mediaId: false,
  };
  qywxPrintReport(param).then((res) => {
    item.downLoading = false;
    if (res.data.msg == "success") {
      window.open(res.data.data.url);
    }
  });
};
//发送税务报表
const sendSwbb = (item) => {
  item.sendLoading = true;
  let param = {
    comIds: [comId.value],
    period: period.value,
    bookNames: [item.tableName],
    mediaId: true,
  };
  qywxPrintReport(param).then((res) => {
    item.sendLoading = false;
    if (res.data.msg == "success") {
      wx.invoke(
        "sendChatMessage",
        {
          msgtype: "file", //消息类型，必填
          file: {
            mediaid: res.data.data.mediaId, //文件的素材id
          },
        },
        function (res) {
          if (res.err_msg == "sendChatMessage:ok") {
            ElMessage.success("发送成功");
          } else {
          }
        }
      );
    }
  });
};

defineExpose({
  init,
});
</script>

<style scoped lang="scss">
:deep(.el-radio-button__inner) {
  padding: 8px 40px;
}

.top_content {
  padding: 10px 0;
  border-top: 1px solid #efefef;
  border-bottom: 1px solid #efefef;
  background: #fff;
}

.date {
  padding-left: 10px;
  display: flex;
  align-items: center;
}

.list_div {
  display: flex;
  flex-direction: column;

  .div_box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;
    margin-left: 10px;
    margin-right: 10px;

    .bb_name {
      margin-left: 6px;
      font-size: 14px;
    }

    .button_send {
      border: 1px solid #3c6ff2;
      width: 60px;
      height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 13px;
      color: #3c6ff2;
      border-radius: 3px;
      font-weight: 500;
    }

    .button_download {
      border: 1px solid #bebdbd;
      width: 60px;
      height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #b3b1b1;
      font-size: 13px;
      border-radius: 3px;
      font-weight: 500;
      margin-right: 10px;
    }
  }
}

.baobiao {
  font-size: 25px;
}

.ask {
  font-size: 14px;
  margin-left: 10px;
}

.eyes {
  font-size: 14px;
  margin-right: 10px;
}
.line {
  width: 100%;
  height: 1px;
  background-color: #efefef;
  margin-top: 15px;
}
.top {
  text-align: center;
  padding: 10px 0;
  background-color: #fff;
}
.el-button + .el-button {
  margin-left: 5px;
}
.el-button--small {
  padding: 5px 6px;
}
</style>
