// import Cookies from 'js-cookie'

// 加了多个子域名间共享登录, cookie设置在父域名上. 主要是,要在后端设置cookie的作用域为父域名, 见adminUser中c.SetCookie  by likun

// const TokenKey = 'Admin-Token'
const TokenKey = 'Token'

let domain = 'qizhangfang.net'
if (window.location.hostname == 'localhost') { // for dev
  domain = 'localhost'
}

 // 后端需设置httpOnly为false,否则https模式获取不到cookies,也无法设置

// export function getToken() {
//   // return Cookies.get(TokenKey)
//   return Cookies.get(TokenKey, { domain })
// }

// export function setToken(token) {
//   // return Cookies.set(TokenKey, token)
//   return Cookies.set(TokenKey, token, { domain })
// }

// export function removeToken() {
//   // return Cookies.remove(TokenKey)
//   return Cookies.remove(TokenKey, { domain })
// }


//临时使用
export function getToken() {
  // return Cookies.get(TokenKey)
  return localStorage.getItem("token")
}

export function setToken(token) {
  // return Cookies.set(TokenKey, token)
  return localStorage.setItem("token",token)
}
export function removeToken() {
  // return Cookies.remove(TokenKey)
  return localStorage.removeItem("token")
}