import { createStore } from "vuex";
import {user} from '@/store/modules/user';
export default createStore({
  modules: {
    user
  },
  state: {},
  getters: {},
  mutations: {},
  actions: {}
});
