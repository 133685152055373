import request from "@/utils/request";

export function login(data) {
  return request({
    url: "api/v2/qywxPublic/qywxLogin",
    method: "post",
    data,
  });
}

//查询公司列表
export function qywxComList(data) {
  return request({
    url: "api/v2/qywx/qywxComList",
    method: "post",
    data,
  });
}

//查询公司信息
export function qywxComInfo(data) {
  return request({
    url: "api/v2/qywx/qywxComInfo",
    method: "post",
    data,
  });
}
//查询账期
export function getQywxPeriods(data) {
  return request({
    url: "api/v2/qywx/getQywxPeriods",
    method: "post",
    data,
  });
}
//查询增值税信息
export function qywxTaxVatAmount(data) {
  return request({
    url: "api/v2/qywx/qywxTaxVatAmount",
    method: "post",
    data,
  });
}

//查询所得税信息
export function qywxTaxQuarterAmount(data) {
  return request({
    url: "api/v2/qywx/qywxTaxQuarterAmount",
    method: "post",
    data,
  });
}

//销项发票列表
export function qywxXxfpList(data) {
  return request({
    url: "api/v2/qywx/qywxXxfpList",
    method: "post",
    data,
  });
}

//进项发票列表
export function qywxJxfpList(data) {
  return request({
    url: "api/v2/qywx/qywxJxfpList",
    method: "post",
    data,
  });
}

//发票统计
export function qywxFpList(data) {
  return request({
    url: "api/v2/qywx/qywxFpList",
    method: "post",
    data,
  });
}

//税务报表
export function qywxTaxList(data) {
  return request({
    url: "api/v2/qywx/qywxTaxList",
    method: "post",
    data,
  });
}

//导出报表
export function qywxPrintReport(data) {
  return request({
    url: "api/v2/qywx/qywxPrintReport",
    method: "post",
    data,
  });
}

//crm
export function qywxContract(data) {
  return request({
    url: "api/v2/qywx/qywxContract",
    method: "post",
    data,
  });
}

//查询个税信息
export function gsTaxInfo(data) {
  return request({
    url: "api/v2/qywx/gsTaxInfo",
    method: "post",
    data,
  });
}

//获取税款信息
export function taxParticulars(data) {
  return request({
    url: "api/v2/qywx/taxParticulars",
    method: "post",
    data,
  });
}
