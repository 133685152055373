<template>
  <div v-loading.fullscreen.lock="fullscreenLoading">
    <div class="com">
      <div class="com_box">
        <div class="com_left">
          <el-select
            v-model="comId"
            placeholder="请选择"
            style="width: 80%; margin: 0 10px 0 0"
            @change="changeCom"
          >
            <el-option
              v-for="item in options"
              :key="item.comId"
              :label="item.name"
              :value="item.comId"
            >
            </el-option>
          </el-select>
          <el-icon size="20px" @click="copy">
            <document-copy />
          </el-icon>
        </div>
        <div>
          <!-- <span>一般客户</span> -->
          <span
            v-if="comInfo.type == '2'"
            style="border: 1px solid #ff7a00; color: #ff7a00"
            >一般纳税人</span
          >
          <span v-else>小规模纳税人</span>
          <!-- <ww-open-data :type="userName" openid="woYTEESQAAQueHtQxwHMRwkPB1LMMiBg" /> -->
        </div>
      </div>
    </div>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="首页" name="1">
        <homePage ref="HomePage" />
      </el-tab-pane>
      <el-tab-pane label="客户" name="2">
        <customer ref="Customer" />
      </el-tab-pane>
      <!-- <el-tab-pane label="收费" name="3">
        <charge ref="Charge" />
      </el-tab-pane> -->
      <el-tab-pane label="云盘" name="4">
        <cloudDisk ref="CloudDisk" />
      </el-tab-pane>
      <el-tab-pane label="发票" name="5">
        <invoice ref="Invoice" />
      </el-tab-pane>
      <el-tab-pane label="财务" name="6">
        <finance ref="Finance" />
      </el-tab-pane>
      <el-tab-pane label="智库" name="7">
        <thinkTank ref="ThinkTank" />
      </el-tab-pane>
      <!--  <el-tab-pane label="项目" name="8">
        <project ref="Project" />
      </el-tab-pane>
      <el-tab-pane label="更多" name="9">
        <more ref="More" />
      </el-tab-pane> -->
    </el-tabs>
  </div>
</template>
<script setup>
import { ref, onMounted, getCurrentInstance, onBeforeMount } from "vue";
import axios from "axios";
import { getUrlParams } from "@/utils/fun";
import { ElMessage } from "element-plus";
import homePage from "./components/homePage.vue";
import customer from "./components/customer.vue";
import charge from "./components/charge.vue";
import cloudDisk from "./components/cloudDisk.vue";
import invoice from "./components/invoice.vue";
import finance from "./components/finance.vue";
import thinkTank from "./components/thinkTank.vue";
import project from "./components/project.vue";
import more from "./components/more.vue";
import { qywxComList, qywxComInfo, login } from "@/api/home";
const { proxy } = getCurrentInstance();
const activeName = ref("1");
const comId = ref(null);
const options = ref([]);
const comInfo = ref({});
const HomePage = ref();
const Customer = ref();
const Charge = ref();
const CloudDisk = ref();
const Invoice = ref();
const Finance = ref();
const ThinkTank = ref();
const Project = ref();
const More = ref();
const fullscreenLoading = ref(false);
const chatId = ref("");
onBeforeMount(() => {
  let url = window.location.href;
  let auth_code = getUrlParams(url, "auth_code");
  if (auth_code != "") {
    fullscreenLoading.value = true;
    axios
      .post("https://public.listensoft.net/api/getPermanentCodeByAuthCode", {
        auth_code: auth_code,
      })
      .then((res) => {
        if (res.data.msg == "success") {
          fullscreenLoading.value = false;
        }
      });
  } else {
    fullscreenLoading.value = true;
    axios
      .post("https://public.listensoft.net/api/getVisitorInfo", {
        url: url,
      })
      .then((res) => {
        if (res.data.msg == "success") {
          let agentConfigParams = res.data.data.agentConfigParams;
          let configParams = res.data.data.configParams;
          wx.config({
            beta: true, // 必须这么写，否则wx.invoke调用形式的jsapi会有问题
            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: configParams.appId, // 必填，企业微信的corpID，必须是本企业的corpID，不允许跨企业使用
            timestamp: configParams.timestamp, // 必填，生成签名的时间戳
            nonceStr: configParams.nonceStr, // 必填，生成签名的随机串
            signature: configParams.signature, // 必填，签名，见 附录-JS-SDK使用权限签名算法
            jsApiList: [
              "openExistedChatWithMsg",
              "getCurExternalChat",
              "sendChatMessage",
            ], // 必填，需要使用的JS接口列表，凡是要调用的接口都需要传进来
          });
          wx.agentConfig({
            corpid: agentConfigParams.corpid, // 必填，企业微信的corpid，必须与当前登录的企业一致
            agentid: agentConfigParams.agentid, // 必填，企业微信的应用id （e.g. 1000247）
            timestamp: agentConfigParams.timestamp, // 必填，生成签名的时间戳
            nonceStr: agentConfigParams.nonceStr, // 必填，生成签名的随机串
            signature: agentConfigParams.signature, // 必填，签名，见附录-JS-SDK使用权限签名算法
            jsApiList: [
              "openExistedChatWithMsg",
              "getCurExternalChat",
              "sendChatMessage",
              // "selectExternalContact"
            ], //必填，传入需要使用的接口名称
            success: function (res) {
              // 回调
              // console.log(res);
              ff();
            },
            fail: function (res) {
              fullscreenLoading.value = false;
              // console.log("err", res);
              if (res.errMsg.indexOf("function not exist") > -1) {
                alert("版本过低请升级");
              }
            },
          });
        } else {
          if (res.data.msg.indexOf("invalid code") > -1) {
            ff();
          }
        }
      });
  }
});
const ff = () => {
  wx.invoke("getCurExternalChat", {}, function (res) {
    if (res.err_msg == "getCurExternalChat:ok") {
      let chatIdVal = res.chatId; //返回当前外部群的群聊ID
      // localStorage.setItem("chatId", chatId);
      chatId.value = chatIdVal;
      getList();
    } else {
      // alert("error", res.err_msg);
    }
  });
};
// onMounted(() => {
//   getList();
// });
const getList = async () => {
  await request();
  setTimeout(() => {
    qywxComList({}).then((res) => {
      fullscreenLoading.value = false;
      if (res.data.msg == "success") {
        options.value = res.data.data;
        if (res.data.data != null && res.data.data.length != 0) {
          comId.value = res.data.data[0].comId;
        }
        changeCom();
      }
    });
  }, 150);
};
const request = () => {
  if (!chatId.value) {
    ElMessage.error("所属群未绑定机构，无法登录");
    return;
  }
  login({ chatId: chatId.value }).then((res) => {
    if (res.data.msg == "success") {
      localStorage.setItem("token", res.data.token);
    } else {
      ElMessage.error("暂未绑定公司，请在系统内绑定");
      localStorage.removeItem("token");
      return;
    }
  });
};
const changeCom = () => {
  proxy.$store.dispatch("user/InitPeriod", comId.value).then(() => {
    qywxComInfo({
      comId: comId.value,
    }).then((res) => {
      if (res.data.msg == "success") {
        comInfo.value = res.data.data;
        if (activeName.value == "1") {
          HomePage.value.init(comId.value, comInfo.value.name);
        } else if (activeName.value == "2") {
          Customer.value.init(comId.value);
        } else if (activeName.value == "3") {
          Charge.value.init(comId.value);
        } else if (activeName.value == "4") {
          CloudDisk.value.init(comId.value, comInfo.value.taxNo);
        } else if (activeName.value == "5") {
          Invoice.value.init(comId.value, comInfo.value.name);
        } else if (activeName.value == "6") {
          Finance.value.init(comId.value, comInfo.value.accountSystem);
        } else if (activeName.value == "7") {
          ThinkTank.value.init(comId.value);
        } else if (activeName.value == "8") {
          Project.value.init(comId.value);
        } else if (activeName.value == "9") {
          More.value.init(comId.value);
        }
      }
    });
  });
};
const handleClick = (tab) => {
  if (tab.props.name == "1") {
    HomePage.value.init(comId.value, comInfo.value.name);
  } else if (tab.props.name == "2") {
    Customer.value.init(comId.value);
  } else if (tab.props.name == "3") {
    Charge.value.init(comId.value);
  } else if (tab.props.name == "4") {
    CloudDisk.value.init(comId.value, comInfo.value.taxNo);
  } else if (tab.props.name == "5") {
    Invoice.value.init(comId.value, comInfo.value.name);
  } else if (tab.props.name == "6") {
    Finance.value.init(comId.value, comInfo.value.accountSystem);
  } else if (tab.props.name == "7") {
    ThinkTank.value.init(comId.value);
  } else if (tab.props.name == "8") {
    Project.value.init(comId.value);
  } else if (tab.props.name == "9") {
    More.value.init(comId.value);
  }
};
const copy = () => {
  let a = "";
  options.value.map((item) => {
    if (comId.value == item.comId) {
      a = item.name;
    }
  });
  //创建input标签
  var input = document.createElement("input");
  //将input的值设置为需要复制的内容
  input.value = a;
  //添加input标签
  document.body.appendChild(input);
  //选中input标签
  input.select();
  //执行复制
  document.execCommand("copy");
  //成功提示信息
  ElMessage.success("复制成功！");
  //移除input标签
  document.body.removeChild(input);
};
</script>
<style scoped lang="scss">
.com {
  padding: 20px 10px;
  border-bottom: 1px solid #efefef;
  background-color: #fff;
}

.com_box {
  width: 98%;
  margin: 0 auto;

  span {
    padding: 2px 7px;
    border: 1px solid $color-main;
    color: $color-main;
    margin-right: 8px;
    font-size: 14px;
    border-radius: 3px;
  }
}

:deep(.el-tabs__item) {
  padding: 0 8px;
}

.com_left {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

:deep(.el-tabs__header) {
  background-color: #fff !important;
  padding: 0 10px;
  margin: 0;
}
</style>
