<template>
  <div class="main" v-loading="loading">
    <div class="top_select">
      <div class="top_input">
        <el-input
          placeholder="请输入内容"
          v-model="listQuery.title"
          @keyup.enter="getList"
          size="small"
        >
        </el-input>
        <el-button type="primary" icon="Search" @click="getList" size="small"
          >搜索</el-button
        >
      </div>
      <div class="top_input" style="padding-top: 0">
        <el-select
          v-model="listQuery.groupId"
          placeholder="请选择"
          size="small"
          @change="getList"
          style="margin: 0 5px 0 0; width: 130px"
        >
          <el-option
            v-for="item in groupList"
            :key="item.id"
            :label="item.groupName"
            :value="item.id"
          >
          </el-option>
        </el-select>
        <el-button
          type="primary"
          icon="Operation"
          size="small"
          plain
          @click="groupManage"
          >分组管理</el-button
        >
      </div>
    </div>
    <div class="add_btn">
      <el-button
        size="medium"
        class="outline_success"
        icon="Plus"
        style="width: 100%"
        @click="addThink"
        >新建内容</el-button
      >
    </div>
    <div class="list" v-for="(item, index) in list" :key="index">
      <div class="list_size">
        <div class="list_title">{{ item.title }}</div>
        <div class="list_text">{{ item.content }}</div>
        <div style="text-align: right">
          <el-button size="small" class="outline" @click="editThink(item)"
            >编辑</el-button
          >
          <el-button
            size="small"
            icon="promotion"
            class="outline"
            @click="send(item)"
            >发送</el-button
          >
        </div>
      </div>
    </div>
    <div v-if="list.length == 0">
      <el-empty :image-size="200"></el-empty>
    </div>
  </div>
  <!-- 新增/编辑智库 -->
  <el-dialog
    :close-on-click-modal="false"
    v-model="dialogThinkVisible"
    :title="textMap[dialogStatus]"
    width="95%"
    destroy-on-close
  >
    <el-form :model="temp">
      <el-form-item label="分组" :label-width="formLabelWidth">
        <el-select
          v-model="temp.groupId"
          placeholder="请选择"
          size="small"
          style="margin: 0 5px 0 0"
        >
          <el-option
            v-for="item in groupList"
            :key="item.id"
            :label="item.groupName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="标题" :label-width="formLabelWidth">
        <el-input v-model="temp.title" placeholder="请输入标题"></el-input>
      </el-form-item>
      <el-form-item label="内容" :label-width="formLabelWidth">
        <el-input
          type="textarea"
          placeholder="请输入内容"
          v-model="temp.content"
          maxlength="200"
          show-word-limit
          :autosize="{ minRows: 3, maxRows: 5 }"
        >
        </el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button size="small" @click="dialogThinkVisible = false"
          >取消</el-button
        >
        <el-button size="small" type="primary" @click="updateThink"
          >确定</el-button
        >
      </span>
    </template>
  </el-dialog>
  <!-- 新增/编辑智库 -->
  <!-- 分组管理 -->
  <el-dialog
    :close-on-click-modal="false"
    v-model="dialogGroupVisible"
    title="分组管理"
    width="95%"
    destroy-on-close
  >
    <div>
      <div>
        <el-button type="primary" size="small" plain @click="addGroup"
          >新建分组</el-button
        >
      </div>
      <div class="group">
        <el-scrollbar max-height="300px">
          <div v-for="(item, index) in groupList" class="group_list">
            <div>{{ item.groupName }}</div>
            <div v-if="item.id != 0">
              <el-icon color="#3c6ff2" @click="editGroup(item)">
                <edit /> </el-icon
              ><el-icon
                color="red"
                style="margin-left: 10px"
                @click="delGroup(item)"
              >
                <delete />
              </el-icon>
            </div>
          </div>
        </el-scrollbar>
      </div>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button size="small" @click="dialogGroupVisible = false"
          >关闭</el-button
        >
      </span>
    </template>
  </el-dialog>
  <!-- 分组管理 -->
  <!-- 新增/编辑分组 -->
  <el-dialog
    :close-on-click-modal="false"
    v-model="dialogFormGroupVisible"
    title="分组操作"
    width="80%"
    destroy-on-close
  >
    <el-form :model="form">
      <el-form-item label="分组名称" :label-width="formLabelWidth">
        <el-input v-model="form.groupName" placeholder="请输入"></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button size="small" @click="dialogFormGroupVisible = false"
          >取消</el-button
        >
        <el-button size="small" type="primary" @click="updateGroup"
          >确定</el-button
        >
      </span>
    </template>
  </el-dialog>
  <!-- 新增/编辑分组 -->
</template>
<script setup>
import { ref, onMounted, getCurrentInstance, computed, nextTick } from "vue";
import { ElMessage } from "element-plus";
import {
  saveThinkTanksGroup,
  QywxThinkTanksGroupList,
  delThinkTanksGroup,
  saveThinkTanks,
  QywxThinkTanksList,
  delThinkTanks,
} from "@/api/think";
const { proxy } = getCurrentInstance();
const listQuery = ref({
  comId: null,
  title: "",
  groupId: 0,
});
const textMap = ref({
  update: "修改",
  create: "新增",
});
const dialogStatus = ref("");
const list = ref([]);
const temp = ref({
  title: "",
  content: "",
});
const form = ref({
  groupName: "",
});
const dialogThinkVisible = ref(false);
const dialogGroupVisible = ref(false);
const dialogFormGroupVisible = ref(false);
const loading = ref(false);
const groupList = ref([]);
const init = (id) => {
  listQuery.value.comId = id;
  getList();
  getGroupList();
};
const getList = () => {
  loading.value = true;
  QywxThinkTanksList(listQuery.value).then((res) => {
    loading.value = false;
    if (res.data.msg == "success") {
      list.value = res.data.data.list;
    }
  });
};

//新增智库
const addThink = () => {
  dialogThinkVisible.value = true;
  dialogStatus.value = "create";
  temp.value = {};
};
const updateThink = () => {
  if (!temp.value.title || !temp.value.content) {
    ElMessage.error("请将内容填写完整");
    return;
  }
  temp.value.comId = listQuery.value.comId;
  saveThinkTanks(temp.value).then((res) => {
    if (res.data.msg == "success") {
      ElMessage.success("操作成功");
      dialogThinkVisible.value = false;
      getList();
    }
  });
};
//编辑智库
const editThink = (row) => {
  temp.value = Object.assign({}, row);
  dialogThinkVisible.value = true;
  dialogStatus.value = "update";
};
//发送智库
const send = (row) => {
  loading.value = true;
  wx.invoke(
    "sendChatMessage",
    {
      msgtype: "text", //消息类型，必填
      enterChat: true, //为true时表示发送完成之后顺便进入会话，仅移动端3.1.10及以上版本支持该字段
      text: {
        content: row.title + "\n" + row.content,
      },
    },
    function (res) {
      loading.value = false;
      if (res.err_msg == "sendChatMessage:ok") {
        ElMessage.success("发送成功");
      } else {
      }
    }
  );
};
//分组列表
const getGroupList = () => {
  QywxThinkTanksGroupList({
    comId: listQuery.value.comId,
  }).then((res) => {
    if (res.data.msg == "success") {
      groupList.value = res.data.data.list ? res.data.data.list : [];
      groupList.value.unshift({
        groupName: "默认分组",
        id: 0,
      });
    }
  });
};

//分组管理
const groupManage = () => {
  dialogGroupVisible.value = true;
};
//新增分组
const addGroup = () => {
  form.value = {};
  dialogFormGroupVisible.value = true;
};
const editGroup = (row) => {
  form.value = Object.assign({}, row);
  dialogFormGroupVisible.value = true;
};
const updateGroup = () => {
  if (!form.value.groupName) {
    ElMessage.error("请填写分组名称");
    return;
  }
  form.value.comId = listQuery.value.comId;
  saveThinkTanksGroup(form.value).then((res) => {
    if (res.data.msg == "success") {
      ElMessage.success("操作成功");
      dialogFormGroupVisible.value = false;
      getGroupList();
    }
  });
};
//删除分组
const delGroup = (row) => {
  proxy
    .$confirm("确认删除该分组？", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    })
    .then(() => {
      delThinkTanksGroup({
        id: row.id,
      }).then((res) => {
        if (res.data.msg == "success") {
          ElMessage.success("成功删除");
          getGroupList();
        }
      });
    })
    .catch((err) => {});
};
defineExpose({
  init,
});
</script>
<style scoped lang="scss">
.main {
  padding-bottom: 10px;
}
.top_select {
  width: 100%;
  background: #fff;
  .top_input {
    width: 96%;
    margin: 0 auto;
    padding: 15px 0;
    display: flex;
    align-items: center;
  }
}
.add_btn {
  width: 96%;
  margin: 15px auto;
}
.list {
  width: 96%;
  margin: 0 auto;
  margin-bottom: 10px;
  background-color: #fff;
  border-radius: 4px;
  position: relative;
  .list_size {
    width: 93%;
    margin: 0 auto;
    padding: 10px 0;
    .list_title {
      font-size: 15px;
      font-weight: 600;
    }
    .list_text {
      font-size: 14px;
      margin: 10px 0;
      white-space: pre-line;
      overflow: hidden;
    }
  }
}
.el-input .el-input__suffix {
  display: contents;
}
.group {
  margin-top: 20px;
  .group_list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    padding-bottom: 8px;
    border-bottom: 1px solid #efefef;
  }
}
</style>
