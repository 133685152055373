export function getUrlParams(url, name) {
  // 不传name返回所有值，否则返回对应值
  let s = url.split("?");
  if (s.length < 2) {
    return "";
  }
  let cs = s[1];
  let csArr = cs.split("&");
  let val = "";
  csArr.map((v) => {
    if (name == v.split("=")[0]) {
      val = v.split("=")[1];
    }
  });
  return val;
}
