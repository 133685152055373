import request from "@/utils/request";

//新增修改智库分组
export function saveThinkTanksGroup(data) {
  return request({
    url: "api/v2/qywx/saveThinkTanksGroup",
    method: "post",
    data,
  });
}

//智库分组列表
export function QywxThinkTanksGroupList(data) {
  return request({
    url: "api/v2/qywx/QywxThinkTanksGroupList",
    method: "post",
    data,
  });
}

///删除智库分组
export function delThinkTanksGroup(data) {
  return request({
    url: "api/v2/qywx/delThinkTanksGroup",
    method: "post",
    data,
  });
}

//新增修改智库
export function saveThinkTanks(data) {
  return request({
    url: "api/v2/qywx/saveThinkTanks",
    method: "post",
    data,
  });
}

//智库列表
export function QywxThinkTanksList(data) {
  return request({
    url: "api/v2/qywx/QywxThinkTanksList",
    method: "post",
    data,
  });
}

//删除智库
export function delThinkTanks(data) {
  return request({
    url: "api/v2/qywx/delThinkTanks",
    method: "post",
    data,
  });
}
