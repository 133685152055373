import request from "@/utils/request";

// 获取文件list
export function getFFList(data) {
    return request({
      url: "api/v2/pan/getFFList",
      method: "post",
      data,
    });
  }
  
  //文件文件夹删除
  export function delFF(data) {
    return request({
      url: "api/v2/pan/delFF",
      method: "post",
      data,
    });
  }
  
  //新增文件夹
  export function createFolder(data) {
    return request({
      url: "api/v2/pan/createFolder",
      method: "post",
      data,
    });
  }
  
  //上传文件
  export function upload(data) {
    return request({
      url: "api/v2/pan/upload",
      method: "post",
      data,
      // config: {
      //   headers: { 'Content-Type': 'multipart/form-data' }
      // }
    });
  }
