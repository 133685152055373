<template>
  <div class="main" v-loading="loading">
    <div class="info">
      <div class="info_title">
        <div class="title_left">基本信息</div>
        <div style="padding-right: 10px">
          <el-button type="text" icon="Refresh" @click="getList"
            >刷新</el-button
          >
        </div>
      </div>
      <div class="info_main">
        <div class="each_info">
          <div class="each_left">企业全称</div>
          <div class="each_right">{{ comInfo.name }}</div>
        </div>
        <div class="each_info">
          <div class="each_left">税号</div>
          <div class="each_right">{{ comInfo.taxNo }}</div>
        </div>
        <div class="each_info">
          <div class="each_left">企业类型</div>
          <div class="each_right">{{ comInfo.companyType }}</div>
        </div>
        <div class="each_info">
          <div class="each_left">启用账期</div>
          <div class="each_right">{{ comInfo.startAccountPeriod }}</div>
        </div>
        <div class="each_info">
          <div class="each_left">法人</div>
          <div class="each_right">{{ comInfo.manager }}</div>
        </div>
        <div class="each_info">
          <div class="each_left">地址</div>
          <div class="each_right">{{ comInfo.gsdz }}</div>
        </div>
        <div class="each_info">
          <div class="each_left">经营范围</div>
          <div class="each_right">
            {{ comInfo.jyfw }}
          </div>
        </div>
      </div>
      <!-- <div class="info_main">
        <div class="each_info">
          <div class="each_left">客户详情</div>
          <div class="each_right">
            <el-button type="primary" plain size="small">股东信息</el-button>
            <el-button type="primary" plain size="small">分工情况</el-button>
            <el-button type="text" size="small"
              >更多资料<el-icon>
                <arrow-right />
              </el-icon>
            </el-button>
          </div>
        </div>
      </div> -->
    </div>
    <div class="info">
      <div class="info_title">
        <div class="title_left">客户标签</div>
        <div style="padding-right: 10px">
          <el-button
            size="small"
            class="outline"
            @click="showInput"
            v-if="!inputVisible"
            >添加标签</el-button
          >
          <div v-else>
            <el-button size="small" type="primary" @click="saveTag"
              >确定</el-button
            >
            <el-button
              size="small"
              type="info"
              plain
              @click="inputVisible = false"
              >取消</el-button
            >
          </div>
        </div>
      </div>
      <div class="info_tag">
        <el-tag :key="tag" v-for="tag in tagList" :disable-transitions="false">
          {{ tag.title }}
        </el-tag>
        <el-input
          class="input-new-tag"
          v-if="inputVisible"
          v-model="title"
          ref="saveTagInput"
          size="small"
          @keyup.enter="saveTag"
        >
        </el-input>
        <!--  <el-button v-if="inputVisible" class="button-new-tag" size="small" @click="showInput"
          >新增</el-button
        > -->
      </div>
      <div style="text-align: center" v-if="tagList.length == 0">
        此客户暂无标签~
      </div>
    </div>
    <div class="info">
      <div class="info_title">
        <div class="title_left">
          记事本
          <span style="padding-left: 10px">
            <el-checkbox
              v-model="checked"
              label="我关注的"
              @change="getRecordList"
            ></el-checkbox
          ></span>
        </div>
        <div style="padding-right: 10px">
          <el-button
            size="small"
            class="outline"
            @click="showTextarea"
            v-if="!textareaVisible"
            >添加记事本</el-button
          >
          <div v-else>
            <el-button size="small" type="primary" @click="saveRecord"
              >确定</el-button
            >
            <el-button
              size="small"
              type="info"
              plain
              @click="textareaVisible = false"
              >取消</el-button
            >
          </div>
        </div>
      </div>
      <div style="width: 98%; margin: 10px auto">
        <el-input
          type="textarea"
          placeholder="请输入内容"
          v-model="content"
          maxlength="100"
          show-word-limit
          v-if="textareaVisible"
          :autosize="{ minRows: 3, maxRows: 5 }"
        >
        </el-input>
      </div>
      <div v-for="(item, index) in recordList" :key="index" class="list_box">
        <div class="list_content">
          <div class="list_left">
            <div class="left_time">
              {{ item.recordTime }}
            </div>
            <div class="left_text">
              {{ item.content }}
            </div>
          </div>
          <div class="list_right">
            <i
              class="iconfont icon-xingxing1"
              @click="focusRecord(item)"
              v-if="!item.status"
            ></i>
            <i
              class="iconfont icon-xingxing2"
              v-else
              style="color: orange"
              @click="cancelFocusRecord(item)"
            ></i>
          </div>
        </div>
      </div>
      <div
        style="text-align: center; margin-top: 10px"
        v-if="recordList.length == 0"
      >
        此客户暂无记事本~
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, getCurrentInstance } from "vue";
import { qywxComInfo } from "@/api/home";
import {
  saveQywxRecord,
  delQywxRecord,
  qywxRecordList,
  qywxLabelList,
} from "@/api/customer";
import { ElMessage } from "element-plus";
const { proxy } = getCurrentInstance();
const test = ref(false);
const checked = ref(false);
const comId = ref(null);
const saveTagInput = ref(null);
const inputVisible = ref(false);
const comInfo = ref({});
const loading = ref(false);
const tagList = ref([]);
const title = ref("");
const recordList = ref([]);
const content = ref("");
const textareaVisible = ref(false);
const init = async (id) => {
  comId.value = id;
  test.value = true;
  getList();
  getRecordList();
  getLabelList();
};
const getList = () => {
  loading.value = true;
  qywxComInfo({
    comId: comId.value,
  }).then((res) => {
    loading.value = false;
    if (res.data.msg == "success") {
      comInfo.value = res.data.data;
    }
  });
};
//获取记事本
const getRecordList = () => {
  let status = "";
  if (checked.value) {
    status = "1";
  } else {
    status = "";
  }
  qywxRecordList({
    comId: comId.value,
    status: status,
  }).then((res) => {
    if (res.data.msg == "success") {
      if (res.data.data.list && res.data.data.list != null) {
        recordList.value = res.data.data.list;
      }
    }
  });
};
//获取标签
const getLabelList = () => {
  qywxLabelList({
    comId: comId.value,
  }).then((res) => {
    if (res.data.msg == "success") {
      if (res.data.data.list && res.data.data.list != null) {
        tagList.value = res.data.data.list;
      }
    }
  });
};
//添加标签
const showInput = () => {
  inputVisible.value = true;
};
const saveTag = () => {
  let param = {
    recordType: "1",
    comId: comId.value,
    title: title.value,
  };
  saveQywxRecord(param).then((res) => {
    if (res.data.msg == "success") {
      ElMessage.success("新增成功");
      inputVisible.value = false;
      title.value = "";
      getLabelList();
    }
  });
};
//添加记事本
const showTextarea = () => {
  textareaVisible.value = true;
};
const saveRecord = () => {
  let param = {
    recordType: "2",
    comId: comId.value,
    content: content.value,
  };
  saveQywxRecord(param).then((res) => {
    if (res.data.msg == "success") {
      ElMessage.success("新增成功");
      textareaVisible.value = false;
      getRecordList();
    }
  });
};
//关注
const focusRecord = (item) => {
  item.status = "1";
  saveQywxRecord(item).then((res) => {
    if (res.data.msg == "success") {
      getRecordList();
    }
  });
};
//取消关注
const cancelFocusRecord = (item) => {
  item.status = "";
  saveQywxRecord(item).then((res) => {
    if (res.data.msg == "success") {
      getRecordList();
    }
  });
};
defineExpose({
  init,
});
</script>

<style scoped lang="scss">
.main {
  width: 96%;
  margin: 0 auto;
  padding-bottom: 20px;
}
.info {
  padding: 15px 0;
  background-color: #fff;
  border: 1px solid #efefef;
  border-radius: 8px;
  margin-top: 15px;
  font-size: 14px;
  .info_title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .title_left {
      padding-left: 10px;
      border-left: 2px solid #3c6ff2;
    }
  }
  .info_main {
    width: 85%;
    margin: 0 auto;
  }
  .info_tag {
    padding: 10px 10px 10px 15px;
  }
}
.each_info {
  display: flex;
  font-size: 14px;
  margin-top: 10px;
  .each_left {
    width: 90px;
    margin-right: 10px;
    color: #999;
    text-align: justify;
    text-align-last: justify;
  }
  .each_right {
    width: 80%;
    &.active {
      height: auto !important;
    }
    .moreBtn {
      white-space: pre-wrap;
      word-break: break-word;
      color: #3c6ff2;
    }
  }
}
.el-checkbox {
  height: auto;
}
.el-tag {
  margin-top: 5px;
}
.el-tag + .el-tag {
  margin-left: 10px;
}
.button-new-tag {
  margin-left: 10px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag {
  width: 60px;
  margin-left: 10px;
  vertical-align: bottom;
  margin-top: 5px;
}
.list_box {
  padding: 0 10px 0 15px;
  margin-bottom: 10px;
  font-size: 14px;
  .list_content {
    display: flex;
    justify-content: space-between;
    .list_left {
      width: 90%;

      .left_time {
        font-size: 12px;
        color: #999;
        padding-bottom: 4px;
      }
      .left_text {
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }
}
</style>
