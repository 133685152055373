import request from "@/utils/request";

//新增修改记事本
export function saveQywxRecord(data) {
  return request({
    url: "api/v2/qywx/saveQywxRecord",
    method: "post",
    data,
  });
}

//删除记事本标签
export function delQywxRecord(data) {
  return request({
    url: "api/v2/qywx/delQywxRecord",
    method: "post",
    data,
  });
}

///查询记事本
export function qywxRecordList(data) {
  return request({
    url: "api/v2/qywx/qywxRecordList",
    method: "post",
    data,
  });
}

//查询标签
export function qywxLabelList(data) {
  return request({
    url: "api/v2/qywx/qywxLabelList",
    method: "post",
    data,
  });
}
