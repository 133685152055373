export function parseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null;
  }
  const format = cFormat || "{y}-{m}-{d} {h}:{i}:{s}";
  let date;
  if (typeof time === "object") {
    date = time;
  } else {
    if (("" + time).length === 10) time = parseInt(time) * 1000;
    date = new Date(time);
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay(),
  };
  const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
    let value = formatObj[key];
    // Note: getDay() returns 0 on Sunday
    if (key === "a") {
      return ["日", "一", "二", "三", "四", "五", "六"][value];
    }
    if (result.length > 0 && value < 10) {
      value = "0" + value;
    }
    return value || 0;
  });
  return time_str;
}

export function time5toTime(oadate) {
  var d = new Date();
  d.setTime(
    Math.round(oadate * 24 * 60 * 60 * 1000) + Date.parse("1899-12-30")
  );
  return d;
}

// 获取默认当前做账月份 格式2019-02 例如当前日期2019-03-02 => 2019-02
export function currentAccountPeriod() {
  var date = new Date();
  var seperator1 = "-";
  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  var strDate = date.getDate();
  if (month >= 1 && month <= 9) {
    month = "0" + month;
  }
  if (strDate >= 0 && strDate <= 9) {
    strDate = "0" + strDate;
  }
  var currentdate = year + seperator1 + month; // + seperator1 + strDate;
  let y = currentdate.split("-")[0];
  let m = currentdate.split("-")[1];
  if (m == "01") {
    return String(Number(y) - 1) + "12";
  } else {
    let x = Number(m) - 1;
    if (x < 10) {
      x = "0" + String(x);
    }
    return String(y) + x;
  }
}

export function lastAccountPerio(val) {
  let year = val.slice(0, 4);
  let month = val.slice(4);
  if (month == "01") {
    year = year - 1 + "";
    month = "12";
  } else if (month == "10" || month == "11" || month == "12") {
    month = "" + (month - 1);
    year = year;
  } else {
    month = "0" + (month - 1);
    year = year;
  }
  return year + month;
}
export function getLastDay(period) {
    let s = period.slice(0, 4) + "-" + period.slice(4, 6);
    var endDate = new Date(s);
    var currentMonth = endDate.getMonth();
    var nextMonth = ++currentMonth;
    var nextMonthFirstDay = new Date(endDate.getFullYear(), nextMonth, 1);
    var oneDay = 1000 * 60 * 60 * 24;
    var lastTime = new Date(nextMonthFirstDay - oneDay);
    var endMonth = parseInt(lastTime.getMonth() + 1);
    var endDay = lastTime.getDate();
    if (endMonth < 10) {
      endMonth = "0" + endMonth;
    }
    if (endDay < 10) {
      endDay = "0" + endDay;
    }
    return endDate.getFullYear() + "-" + endMonth + "-" + endDay;
  }
  
  export function getHeight(height) {
    return window.innerHeight - height + "px";
  }
  
  export function copy(obj) {
    if (!obj) {
      return;
    }
    var newobj = obj.constructor === Array ? [] : {};
    if (typeof obj !== "object") {
      return;
    }
    for (var i in obj) {
      newobj[i] = typeof obj[i] === "object" ? copy(obj[i]) : obj[i];
    }
    return newobj;
  }
  